import axios from 'axios';
// import storage from '@Services.App/storage';

const TIMEOUT = 30000; // 6 seconds

const CONTEN_TYPE_JSON = 'application/json';

axios.defaults.headers.post['Content-Type'] = CONTEN_TYPE_JSON;
axios.defaults.timeout = TIMEOUT;
const UNKNOWN_ERR_MSG =
    'An unknown server error has occurred or maybe the server is unreachable. Please get in touch with the admin team.';

// axios.interceptors.request.use((config) => {
//     const token = storage.getAuthToken();
//     token && (config.headers.Authorization = `Bearer ${token}`);
//     return config;
// });

class Http {
    makeRequest = ({ method = 'get', headers = {}, url = '/', data = {}, params = {} }) => {
        return new Promise((resolve, reject) => {
            axios({ method, headers, url, data, params })
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error.response) {
                        console.error(error.response);
                        reject({
                            status: error.response.status,
                            data: error.response.data,
                            message:
                                error.response.data && error.response.data.message
                                    ? error.response.data.message
                                    : UNKNOWN_ERR_MSG,
                            error: new Error(
                                error.response.data && error.response.data.message
                                    ? error.response.data.message
                                    : UNKNOWN_ERR_MSG
                            )
                        });
                    } else if (error.request) {
                        console.error(error.request);
                        reject({
                            message: new Error(error.request && error.message ? error.message : UNKNOWN_ERR_MSG)
                        });
                        // reject({ error: new Error(UNKNOWN_ERR_MSG) });
                    } else {
                        // console.error(error.message);
                        reject({ message: new Error(UNKNOWN_ERR_MSG) });
                    }
                });
        });
    };

    get = (params) => {
        return this.makeRequest({ ...params, method: 'get' });
    };

    post = (params) => {
        return this.makeRequest({ ...params, method: 'post' });
    };

    put = (params) => {
        return this.makeRequest({ ...params, method: 'put' });
    };

    delete = (params) => {
        return this.makeRequest({ ...params, method: 'delete' });
    };
}

export default new Http();
