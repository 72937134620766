import React from "react";
import Navbar from "@Components/Navbar";
import Footer from "@Components/Footer";
import settingScreenshotImg from "@Images/Settings.jpg";

const GdprUpdate = () => {
  return (
    <div>
      <Navbar />
      <div className="min-h-full mb-20 font-body">
        <div className="mx-4 sm:mx-6 md:mx-10 lg:mx-32">
          <p className="mt-20 text-3xl font-medium font-body">
            How do I request a copy of my personal data or delete my
            account/data?
          </p>

          <p className="mt-10 text-2xl font-body">
            How to request a copy of my personal data?
          </p>
          <p className="mt-1 ">
            At Parooz, we are committed to protecting your data and privacy as
            well as providing you access to the information you have provided
            us.
          </p>
          <p>
            If you would like to request a copy of your personal data, please
            email us at
            <span className="text-blue-700 hover:underline">
              <a href="mailto: privacy@getparooz.com"> privacy@getparooz.com</a>
            </span>
            .
          </p>
          <p className="mt-10 text-2xl "> How to delete my account?</p>
          <p className="mt-1">
            If you would like to delete your account, please visit "Account
            Settings" in the app.
          </p>
          <div className="flex items-center justify-center mt-8">
            <img
              className="border-2 "
              style={{ height: "450px", width: "207px" }}
              src={settingScreenshotImg}
            ></img>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GdprUpdate;
