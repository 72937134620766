import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile, isAndroid } from 'react-device-detect';
import parooz_log_white from '@Images/parooz_log_white.png';
import Download_logo from '@Images/Download_logo.png';
import googleplayLogo from '@Images/googleplayLogo.png';
import { Link } from 'react-router-dom';
//https://www.youtube.com/watch?v=cHqxgLhOl5Y&t=50s
const Footer = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage('en');
    }, []);
    return (
        <footer className="bg-black pt-8 text-center lg:text-left">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 mb-8">
                        <Link to="/">
                            <img
                                src={parooz_log_white}
                                className="lg:ml-0 m-auto"
                                alt={t('Footer.ParoozLogoAlt')}></img>
                        </Link>
                        <h5 className="text-lg hidden lg:block mt-0 mb-2 text-white font-normal">
                            {t('Footer.copyRight')}
                        </h5>
                    </div>
                    <div className="w-full lg:w-6/12 px-4 mb-8">
                        <div className="flex flex-wrap items-top mb-6">
                            <div className="w-full lg:w-4/12 px-4 ml-auto mb-8">
                                {/* <span className="block  text-gray-400 text-lg font-medium mb-2">
                                    {t('Footer.Parooz')}
                                </span> */}

                                <ul className="list-unstyled">
                                    {/* <li>
                                        <a className="text-white  font-bold block pb-2 text-lg">
                                            {t('Footer.Features')}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-white  font-bold block pb-2 text-lg">{t('Footer.About')}</a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="w-full lg:w-4/12 px-4 mb-8">
                                <span className="block  text-gray-400 text-lg font-medium mb-2">
                                    {t('Footer.Company')}
                                </span>
                                <ul className="list-unstyled">
                                    <li>
                                        <Link to="/privacy">
                                            <a className="text-white  font-bold block pb-2 text-lg">
                                                {t('Footer.Privacy')}
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/term">
                                            <a className="text-white  font-bold block pb-2 text-lg">
                                                {t('Footer.Terms')}
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/gdpr_update">
                                            <a className="text-white  font-bold block pb-2 text-lg">
                                                {t('Footer.gdprText')}
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="w-full lg:w-4/12 px-4 ">
                                <span className="block  text-gray-400 text-lg font-medium mb-2">
                                    {t('Footer.MeetSomeoneToday')}
                                </span>
                                <ul className="list-unstyled">
                                    <li>
                                        <a
                                            className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                                            href={isAndroid ? t('links.AndroidAppLink') : t('links.IosAppLink')}
                                            target="_blank">
                                            <img
                                                src={isAndroid ? googleplayLogo : Download_logo}
                                                className="lg:ml-0 m-auto"
                                                alt={t('Footer.IosLogoAlt')}></img>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <h5 className="text-xs lg:hidden mt-0 mb-2 text-white font-normal text-center">
                            {t('Footer.copyRight')}
                        </h5>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
