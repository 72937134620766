import React, { useEffect, useState } from 'react';
import logo from '@Images/parooz_logo.png';
import menuIcon from '@Images/menu_icon.png';
import { Link } from 'react-router-dom';
import { isAndroid } from 'react-device-detect';
import chatGroup from '@Images/chatGroup.png';
import closeIcon from '@Images/close.png';
import { useTranslation } from 'react-i18next';
const Navbar = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage('en');
    }, []);
    const [menuOpen, setMenuOpen] = useState(false);
    const handleToggle = (e) => {
        console.log(e, 'rhis is the');
        //         alert(!menuOpen, 'update value');
        setMenuOpen(!menuOpen);
    };
    return (
        <header className="lg:px-16 px-6 bg-white flex flex-wrap items-center absolute top-0 w-full  lg:relative lg:py-0 py-2">
            <div className="flex-1 flex justify-between items-center">
                <Link to="/">
                    <img src={logo} alt={t('navbar.ParoozLogoAlt')} />
                </Link>
            </div>
            <label for="menu-toggle" className="cursor-pointer lg:hidden block">
                {menuOpen ? <img src={closeIcon} /> : <img src={menuIcon} />}
            </label>
            <input
                type="checkbox"
                className="hidden"
                checked={menuOpen}
                id="menu-toggle"
                onChange={(e) => handleToggle(e)}
            />
            <div
                className={
                    menuOpen
                        ? 'block z-50 lg:flex lg:items-center lg:w-auto w-full'
                        : 'hidden lg:flex lg:items-center lg:w-auto w-full'
                }
                id="menu">
                <nav className="p-4">
                    <ul class="lg:flex items-center  justify-between text-base text-gray-700 pt-4 lg:pt-0">
                        {/* <li>
                            <a
                                className="lg:p-4 py-3 px-0 block border-b-2 text-center border-transparent hover:border-indigo-400"
                                href="#">
                                {t('navbar.Features')}
                            </a>
                        </li>
                        <li>
                            <a
                                className="lg:p-4 py-3 px-0 block text-center border-b-2 border-transparent hover:border-indigo-400"
                                href="#">
                                {t('navbar.About')}
                            </a>
                        </li> */}
                        {/* <li>
                            <a
                                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                                href="#">
                                Documentation
                            </a>
                        </li> */}
                        <li>
                            {/* <a
                                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                                href="#">
                                Support
                            </a> */}
                            <a href={isAndroid ? t('links.AndroidAppLink') : t('links.IosAppLink')} target="_blank">
                                <button
                                    class="bg-blue-700 hover:bg-blue-500 m-auto flex text-white  py-2 px-4 rounded-lg"
                                    style={{ backgroundColor: '#0072EE' }}>
                                    {t('navbar.GettheApp')}
                                </button>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
