import React from 'react';
import Navbar from '@Components/Navbar';
import Footer from '@Components/Footer';
const Term = () => {
    return (
        <>
            <Navbar />
            <div className="mt-20 lg:mt-0 leading-7">
                <div className="mx-4 sm:mx-6 md:mx-10 lg:mx-32">
                    <h1 className="mt-8 lg:mt-8 text-2xl">PAROOZ TERMS OF SERVICE</h1>
                    <p>
                        Tevis, LLC. (“we,” “our,” “us,“ or “Company”) provides the Parooz application (the “App,” or
                        “Parooz“) for use in accordance with the below terms, obligations, responsibilities, and
                        restrictions.
                    </p>
                    <p className="mb-8">
                        PLEASE READ THIS AGREEMENT, THE TERMS HEREIN, AND OUR PRIVACY POLICY CAREFULLY BEFORE
                        DOWNLOADING OR USING PYAR ROCKS OR USING ANY OTHER RELATED SERVICES.
                    </p>
                    <p className="mt-8 lg:mt-8 text-2xl">About Parooz and This Agreement</p>
                    <p>
                        Parooz allows you to provide your own profile and to browse profiles to connect with other
                        people to help you find the true love, casual date, or anything in between.
                    </p>
                    <div className="mx-4 sm:mx-6 md:mx-10 lg:mx-20">
                        <li>
                            Your access to and use of Parooz, the www.getparooz.com website (the “Site”), and all
                            content, user profiles, services, and functionality available at or through Parooz and the
                            Site (the “Services”) are subject to the terms and conditions contained herein (“Terms of
                            Service“). Please read these Terms of Service carefully before accessing or using Parooz in
                            order to fully understand your rights and responsibilities.
                        </li>
                        <li>
                            We reserve the right to modify or amend these Terms of Service at any time. All changes
                            shall be effective immediately upon their posting and your continued access to or use of
                            Parooz constitutes your acceptance and consent to all such changes. Parooz users may be
                            notified of material changes to these Terms of Service when logging on to the app or by
                            email.
                        </li>
                        <li>
                            Without limiting the foregoing, this Agreement also incorporates by reference the Parooz
                            Privacy Policy located at www.getparooz.com/privacy as it may be updated from time to time
                            pursuant to the terms therein (the “Privacy Policy”).
                        </li>
                        <li>
                            We reserve the right to modify any information within Parooz and add or discontinue the
                            Services without any obligation to notify past, current or prospective Parooz users,
                            including you.
                        </li>
                    </div>
                    <p className="mt-8 lg:mt-8 mb-3 text-2xl">Acceptance of This Agreement</p>
                    <p>
                        By checking a box, clicking on, or pressing “I accept”, “I agree” or similar verbiage during the
                        download, installation and/or signup progress, or by accessing or using any part of the
                        Services, you agree to become bound by the Terms of Service, which forms a legally binding
                        contract between you and us (the “Agreement”). If you do not agree to all the Terms of Service,
                        then you may not download Parooz or access or use any of the Services. If you are using the
                        Services on behalf of an employer or another entity, you represent and warrant that you have the
                        authority and capacity to bind such entity to this Agreement. The Services are currently
                        available only to individuals who are at least 18 years old. If you are less than 18 years old,
                        you may not download Parooz or use the Services. You may print a copy of these Terms of Service
                        for your reference.
                    </p>
                    <p className="mt-8 lg:mt-8 text-2xl">Registration</p>
                    <div className="mx-4 sm:mx-6 md:mx-10 lg:mx-20">
                        <li>
                            To use the Services, you must set up an account with us and become a registered member (a
                            “Member”), which is facilitated using Facebook. All registration information you provide
                            must be accurate and such information is subject to our Privacy Policy at www.getparooz.com.
                            We have the right to our review, approve, suspend or terminate your registration and
                            membership at our sole discretion.
                        </li>
                        <li>
                            Each Member is responsible for all activity that takes place under that Member's user ID and
                            account. You may not transfer, assign, or sell your account to any third party. If you
                            become aware of unauthorized access to your account, you must promptly notify us.
                        </li>
                    </div>

                    <p className="mt-8 lg:mt-8 mb-3 text-2xl">Collection of Information</p>
                    <p>
                        For details about our data collection and data use practices, please see our Privacy Policy
                        http://www.getparooz.com/privacy.
                    </p>
                    <p className="mt-8 lg:mt-8 text-2xl">Privacy Policy</p>
                    <p>
                        By your access and use of Parooz, you hereby agree to the terms of the Parooz Privacy Policy,
                        located at http://www.getparooz.com/privacy, which is incorporated into these Terms of Service
                        and your Agreement with us by this reference.
                    </p>

                    <p className="mt-8 lg:mt-8 text-2xl">Connecting Profiles</p>
                    <p>
                        We make no representation or warranty regarding any profile of any person using Parooz (a
                        “Connecting Profile”) or any of the information contained therein.
                    </p>
                    <p className="mt-4 mb-4">
                        Further, we have no liability for any such Connecting Profiles or your interaction with people
                        through such profiles and you agree that we have no liability for any misrepresentation,
                        incomplete statement, or for any claims arising from your use or connection with any person or
                        Connecting Profile as a result of your use of the Parooz or the Services.
                    </p>

                    <p className="mt-8 lg:mt-8 text-2xl">Limited License</p>
                    <p className="mb-4">
                        Parooz grants you a limited, revocable and non-exclusive license to access and use the App for
                        your own personal use. Under this limited license, you agree (i) not to download (other than
                        page caching) or modify the App, or any portion of the App; (ii) not to resell or make other
                        commercial use of the App, its contents, or the Services; (iii) not to collect, aggregate, or
                        make the contents of the any Connecting Profile available to third parties, other than for your
                        own personal and non-commercial use; (iv) not to use the App or Services for any derivative use
                        not authorized by us; (v) not to use, download, or copy any account information that is not your
                        own; (vi) not to use data mining, bots, or similar data gathering and extraction tools; and
                        (vii) not to reproduce, duplicate, copy, sell, resell, or otherwise use the App for any
                        commercial purpose. Your access to Parooz will be terminated upon any unauthorized use.
                    </p>
                    <p>
                        Your compliance with the requirements in this section (Limited License) is a condition to, and
                        material inducement and consideration for, our granting you the right to access and use Parooz
                        and the Services. Any unauthorized use or other activity by you in violation of this Agreement
                        will cause the licenses granted to you by us in this section (Limited License) to terminate
                        immediately and automatically. Nothing in this Agreement transfers or otherwise grants any
                        ownership rights from us to you in any of our intellectual property; and all right, title and
                        interest in, to and under such property will remain solely with the us, or a relevant third
                        party, as applicable. Parooz, Parooz.it, and the Parooz logo, and all other trademarks, service
                        marks, graphics and logos used or displayed in connection with the Services are our trademarks.
                        Your use of the Services grants you no right or license to reproduce or otherwise use any of our
                        or third-party trademarks.
                    </p>
                    <p className="mt-8 lg:mt-8 text-2xl">Acceptable Use Policy</p>
                    <p>
                        Whether or not you register for an account with us, the Services are only available for access
                        and use by you for lawful purposes. You understand that when using the Site or Parooz, you may
                        be exposed to user content provided by other users or third parties, and you agree that we are
                        not responsible for the accuracy, safety, appropriateness or intellectual property rights in or
                        related to such content. Any action by you or associated with your account that we believe, in
                        our sole and absolute discretion: (a) violates this Agreement; (b) violates any applicable law,
                        statute, court order, regulation or ordinance (including, without limitation, those governing
                        export control, consumer protection, unfair or deceptive practices, and false advertising); (c)
                        restricts, inhibits, disrupts, interferes with, limits, or prevents access or use by anyone of
                        the Services; or (d) through the use of the Services, abuses, defames, harasses, libels,
                        disparages or threatens, harms or is likely to harm another user of the Services, any other
                        third party, or the Services themselves, is strictly forbidden.
                    </p>
                    <p className="mt-8 lg:mt-8 text-2xl">Termination</p>
                    <p>
                        We may terminate your access to all or any part of the Services, or block or disable your access
                        or use of Parooz, at any time, with or without cause, with or without notice, effective
                        immediately. Without limiting the foregoing, we may terminate your access to all or any part of
                        the Services, stop accruing points to your account, or block or disable your access or use of
                        Parooz, if (a) we believe that you have breached any provision of the Agreement (or have acted
                        in manner which shows that you do not intend to, or are unable to comply with the provisions of
                        the Agreement); (b) we are required to do so by law (for example, where the provision of Parooz
                        to you is, or becomes, unlawful); (c) we elect to no longer provide Parooz to users in the state
                        or country in which you are resident or from which you use Parooz; or (d) we believe that your
                        use of Parooz may infringe or violate the rights of a third party or subject us or one of our
                        affiliates to civil or criminal liability or reputational harm. Termination may result, in our
                        sole discretion, in the forfeiture and destruction of all information associated with your
                        account and any points earned prior to such date. All provisions of these Terms of Service that
                        by their nature should survive termination of the Agreement, including, without limitation,
                        ownership provisions, warranty disclaimers, indemnity, limitations of liability, and
                        miscellaneous terms, shall so survive. If your account is cancelled or terminated for any
                        reason, you will no longer be able to access information under your account.
                    </p>

                    <p className="mt-8 lg:mt-8 text-2xl">Intellectual Property Rights</p>
                    <p className="font-extralight">
                        As between us and you, the software and content associated with Parooz and the Services,
                        including any enhancements or modifications thereto and any related documentation, as well as
                        the visual and textual elements and the selection, arrangement and compilation of data embodied
                        in (and/or displayed during the execution of) the software, are copyrighted works and
                        exclusively owned by the us or one of our affiliates. In addition, the Services, including all
                        software, content, inventions, technology, products, services and data provided on the Site or
                        through the Services, are protected under United States and international copyright, patent,
                        trademark and/or other intellectual property laws and incorporate valuable confidential
                        information and trade secrets of the us, and/or one of our affiliates, and are our, or our
                        affiliates,’ or their respective licensors exclusive property. All suggestions, requests and
                        feedback you may communicate to us regarding the Services, and all inventions, improvements,
                        modifications, product or service ideas, and concepts contained therein (collectively,
                        “Feedback”), are our exclusive property. You hereby assign all worldwide rights, title and
                        interest in, to and under any such Feedback to us and agree to execute and deliver any
                        assignments or other instruments or documents that we may request in order to confirm, perfect,
                        record or enforce our rights with respect to the Feedback.
                    </p>
                    <p className="font-extralight underline mt-4 text-2xl mb-4">No Liability; Release of Liability</p>
                    <p className="font-extralight ">
                        UNDER NO CIRCUMSTANCES SHALL COMPANY OR ITS AFFILIATES, DIRECTORS, SHAREHOLDERS, OFFICERS,
                        EMPLOYEES, REPRESENTATIVES, AGENTS, OR SUPPLIERS BE LIABLE FOR DIRECT, INDIRECT, INCIDENTAL,
                        SPECIAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOST
                        PROFITS OR DAMAGES ARISING FROM YOUR USE OF OR INABILITY TO USE THE SERVICES) WHATSOEVER,
                        WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE
                        COMPANY OR ANY OF THE FOREGOING PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU
                        AGREE THAT THE LIABILITY OF THE COMPANY AND ITS AFFILIATES, DIRECTORS, SHAREHOLDERS, OFFICERS,
                        EMPLOYEES, REPRESENTATIVES, AGENTS, AND SUPPLIERS FOR ANY CLAIM OR SERIES OF CLAIMS WILL BE
                        LIMITED IN THE AGGREGATE TO FIFTY U.S. DOLLARS ($50.00). YOU HEREBY AGREE TO RELEASE, REMISE AND
                        FOREVER DISCHARGE THE SITE, PAROOZ, THE SERVICES AND COMPANY AND ITS AFFILIATES, DIRECTORS,
                        SHAREHOLDERS, OFFICERS, EMPLOYEES, REPRESENTATIVES, AGENTS, AND SUPPLIERS, AND ITS AND THEIR
                        RESPECTIVE SUCCESSORS AND ASSIGNS, AND ALL OTHER RELATED PERSONS OR ENTITIES, FROM ANY AND ALL
                        MANNER OF RIGHTS, CLAIMS, COMPLAINTS, SUITS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS,
                        LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER
                        KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH YOUR USE
                        OF THE SITE, PAROOZ, OR THE SERVICES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA
                        CIVIL CODE SECTION 1542, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
                        CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                        WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR” (OR ANY
                        EQUIVALENT STATUTORY PROVISION WITH A SIMILAR IMPORT OR INTENT). IF YOU ARE A RESIDENT OF A
                        STATE OTHER THAN CALIFORNIA, YOU EXPLICITLY WAIVE THE TERMS AND PROTECTIONS OF ANY STATUTE OF
                        YOUR OWN STATE THAT HAS A SIMILAR IMPORT OR INTENT.
                    </p>
                    <p className="font-extralight mt-3 ">
                        The foregoing limitations of liability shall apply to the fullest extent permitted by law in the
                        applicable jurisdiction.
                    </p>

                    <p className="mt-4 font-extralight text-2xl mb-4 underline">Your Representations and Warranties</p>
                    <p className="font-extralight ">
                        You represent and warrant that (i) your use of the Services will be in strict accordance with
                        this Agreement and with all applicable laws and regulations (including, without limitation, any
                        local laws or regulations in your country, state, city, or other governmental area, regarding
                        online conduct and acceptable content, and all applicable laws regarding the transmission of
                        technical or personal data exported from or into the United States or the country in which you
                        reside, and all applicable laws pertaining to privacy, consumer rights, and unfair or deceptive
                        advertising practices); and (ii) your use of the Services will not infringe or misappropriate
                        the intellectual property rights or any other rights of Company, its affiliates or of any other
                        user or third party.
                    </p>
                    <p className="mt-4 font-extralight text-2xl mb-4 underline">Our Disclaimer of Warranties</p>
                    <p className="font-extralight mb-3">
                        We take commercially reasonable efforts to ensure that material, information, and data on Parooz
                        are accurate and reliable; however, accuracy cannot be guaranteed.
                    </p>
                    <p className="font-extralight mb-3">
                        YOU AGREE THAT USE OF THE SERVICES IS ENTIRELY AT YOUR OWN RISK. Parooz and the Services are
                        provided to you on an “AS IS” or “AS AVAILABLE” basis, without any warranties of any kind.
                        Company and its affiliates, shareholders, directors, officers, employees, representatives, and
                        agents disclaim all warranties with respect to the Parooz, including without limitation all
                        implied warranties of merchantability, fitness for a particular purpose, title and
                        non-infringement. Company makes no warranty or representation regarding the results that may be
                        obtained from use of the Parooz, or use of material, Connecting Profiles, products, information
                        or data downloaded or otherwise obtained from the App, such as the Services, or regarding the
                        accuracy or reliability of any information obtained from the App. Parooz makes no
                        representations and warranties related to any results you may or may not obtain around your
                        ability to connect with other people to help you find the true love, casual date, or anything in
                        between.
                    </p>
                    <p className="font-extralight">
                        THE COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                        SERVICES CONNECTING PROFILES AND/OR THE CONTENT OF EACH OR THE CONTENT OF ANY PRODUCTS OR
                        SERVICES LINKED TO OR INTEGRATED WITH THE SERVICES AND ASSUMES NO LIABILITY OR RESPONSIBILITY
                        FOR (I) ERRORS, MISTAKES, OMISSIONS OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY
                        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (III)
                        ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICES, YOUR ACCOUNT OR OUR SERVERS, AND/OR ANY AND
                        ALL PERSONAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
                        OR FROM THE SERVICES, (V) ANY BUGS, VIRUSES, TROJAN HORSES, MALWARE, ADWARE AND/OR THE LIKE
                        WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES, (VI) ANY LOSS OR DAMAGE TO CONTENT OR DATA
                        (WHETHER STORED THROUGH THE SERVICES OR OTHERWISE), OR (VII) ANY LOSS OR DAMAGE OF ANY KIND
                        INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, E-MAILED, IMPORTED, TRANSMITTED,
                        DISTRIBUTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. COMPANY DOES NOT WARRANT, ENDORSE,
                        GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY PRODUCT, SERVICE OR OPPORTUNITY ADVERTISED OR OFFERED
                        BY AN ADVERTISERS OR THIRD PARTY THROUGH THE SERVICES, OR ANY HYPERLINKED OR INTEGRATED WEBSITE
                        OR SERVICES, AND COMPANY WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING OR
                        ENFORCING ANY TRANSACTION BETWEEN YOU AND ANY THIRD PARTY (INCLUDING ANY ADVERTISER OR OTHER
                        THIRD PARTY THAT OFFERS OR ATTEMPTS TO TAKE ADVANTAGE OF A DEAL OR PROMOTION THROUGH THE
                        SERVICES). NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICES
                        OR IN ANY MANNER FROM PAROOZ SHALL CREATE ANY WARRANTY.
                    </p>

                    <p className="mt-4 font-extralight text-2xl mb-4 underline">Indemnifications</p>
                    <p className="font-extralight">
                        To the fullest extent permitted by applicable law, you agree to defend, indemnify and hold
                        harmless Company, its affiliates and its and their respective stockholders, directors, officers,
                        employees, representatives and agents from and against any and all claims, actions, lawsuits,
                        damages, obligations, complaints, demands, allegations, losses, liabilities, costs or debt, and
                        expenses (including, but not limited to, attorneys’ fees) arising out of or related to or in
                        connection with your actual or alleged (a) use of and access to the Services, Site and Parooz,
                        (b) violation, breach or default of any term of the Agreement, (c) violation by you of any third
                        party right including, but not limited to, any patent, trademark, service mark, copyright, trade
                        secret, publicity or privacy right, (d) damage to a third party cause by you or any content
                        which you submit or transmit via the Services, or (d) violation, breach or default of any term
                        of an agreement (whether written or oral, and whether implied or express) between you and any
                        other user.
                    </p>
                    <p className="mt-4 font-extralight text-2xl  mb-4 underline">Third-Party Websites</p>
                    <p className="font-extralight">
                        Parooz may link to, or be linked to, other websites not maintained by or related to Parooz
                        (“Third-Party Websites“). Such links are provided only as a service to our visitors. Parooz is
                        not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or
                        affiliation with the linked or linking website, unless specifically stated therein. Parooz has
                        not reviewed all such websites and is not responsible for the content, accuracy, or policies of
                        any such websites. Your visit to any linked Third-Party Website is at your own risk. Without
                        limiting the generality of the foregoing, we shall not be responsible for or have control over
                        any privacy or security practices or any Third Party Site’s collection, storage, use or
                        disclosure of your information. You are responsible for reading and complying with the privacy
                        policies, use or service agreements or terms of use, and any other policies of Third Party
                        Sites.
                    </p>

                    <p className="mt-4 font-extralight  text-2xl mb-4 underline">Entire Agreements</p>
                    <p className="font-extralight mb-3">
                        You agree that no joint venture, partnership, employment or agency relationship exists between
                        you and us or any of our affiliates as a result of this Agreement or your use of the Services.
                        These Terms of Service (including our Privacy Policy and all other operating policies, rules and
                        procedures posted on the Site or on Parooz or made available through the Services from time to
                        time) constitutes the entire agreement between us and you concerning the subject matter hereof,
                        and may only be modified by a written amendment signed by a duly authorized representative of
                        us, or pursuant to the terms set forth in the Updates and Changes section of these Terms of
                        Service.
                    </p>
                    <p className="font-extralight">
                        The provisions of these Terms of Service are severable, and if any portion is determined invalid
                        or unenforceable by a court of competent jurisdiction, the remaining portions will remain valid,
                        enforceable and in effect to the fullest extent consistent with applicable law. Any waiver by
                        either party of any term or condition of the Agreement or any breach thereof shall be in
                        writing, executed by the party to be charged, and any such waiver, in any one instance, will not
                        waive such term or condition in any other instance or any subsequent breach thereof. The
                        Agreement is not assignable by you, except by operation of law; we may assign our rights under
                        the Agreement without condition. The Agreement will be binding upon and will inure to the
                        benefit of the parties, their successors and permitted assigns.
                    </p>
                    <p className="mt-4 font-extralight text-2xl  mb-4 underline">Updates and Changess</p>
                    <p className="font-extralight">
                        From time to time, Parooz may automatically download and install, or may prompt you to download
                        and install, software updates. These updates are designed to improve, enhance and further
                        develop Parooz and may take the form of bug fixes, enhanced functions, new software modules
                        and/or completely new versions. You agree to receive such updates (and permit us to deliver
                        these to you) as part of your continued use of Parooz. You may refuse to download and install
                        such updates, but such refusal may cause your access to Parooz and/or the Services to be
                        disabled. You acknowledge and agree that the form, features and/or nature of Parooz and the
                        Services may change from time to time without prior notice to you. We may also cease or
                        discontinue providing Parooz or the Services, or support or upgrades for Parooz, at any time at
                        our sole discretion. Such new features and/or services shall be subject to the terms and
                        conditions of the then-current version of these Terms of Service as well as any additional terms
                        and conditions that we may post on the Site or make available through the Services. We may also
                        modify or eliminate services, modules or features of Parooz or the Services; your sole and
                        exclusive remedy, and our sole liability, in connection with any such change, modification or
                        elimination is to terminate your account with us. We reserve the right (and you acknowledge our
                        right), at our sole discretion, to amend or update any part of these Terms of Service by posting
                        the amended or updated Terms of Service on the Site or making them available through Parooz.
                        While we may choose to notify you of changes to these Terms of Service, you are responsible for
                        periodically checking for changes to these Terms of Service. Your continued use of or access to
                        the Services following the posting or distribution of any changes to these Terms of Service
                        constitutes acceptance of those changes; and such amended or updated Agreement between us and
                        you will be effective as of the date of any Terms of Service posting on the Site or being made
                        available through Parooz.
                    </p>

                    <p className="mt-4 font-extralight text-2xl mb-4 underline">Disputes and Arbitration</p>
                    <p className="font-extralight">
                        The Terms of Service and the Agreement is governed by California law, excluding any conflicts of
                        law rules to the contrary. Except as provided in this Section with respect to requests for
                        injunctive relief relating to a breach of your license rights or our intellectual property
                        rights, any controversy, claim or dispute arising out of the Agreement or your use of the
                        Services shall be resolved by final and binding arbitration. Such arbitration shall take place
                        in Los Angeles, California, U.S.A. and shall be administered by a single arbitrator pursuant to
                        the JAMS Comprehensive Arbitration Rules and Procedures. The arbitrator shall be empowered to
                        award any form of individual relief, including injunctive relief. In order to keep costs down
                        for both you and us, hearings may, at our request, be conducted telephonically or entirely upon
                        submissions. If we think you are violating your license to use the Services or our intellectual
                        property or trade secret rights, we may bring an action for injunctive relief in any court of
                        competent jurisdiction. You expressly consent to the exercise of jurisdiction and venue by the
                        state and federal courts located in Los Angeles, California, U.S.A. In addition to the above,
                        you waive the right to bring any claim or dispute related to the Agreement as a class action,
                        consolidated, multi-district or collective action, or private attorney general action. You also
                        agree not to participate in any class action, consolidated, multi-district or collective action,
                        or private attorney general action regarding any claim or dispute related to the Agreement. If
                        we prevail in any arbitration or proceeding to enforce the Agreement or arising out of your
                        access to or use of the Services, we shall be entitled to recover, in addition to all other
                        available legal and equitable relief, its legal costs, including attorneys’ fees.
                    </p>
                    <p className="mt-4 font-extralight text-2xl  mb-4 underline">Notices</p>
                    <p className="mt-4 mb-4">
                        All notices, demands and other communications hereunder shall be in writing and shall be
                        effective upon receipt, provided that we may provide notice to you by posting announcements on
                        the Site (or otherwise making them available through the Services) or sending an e-mail to you
                        at the e-mail address that is currently associated with your account. Any such e-mail notice to
                        you will be deemed given on the day it is sent. Except as specified in the next sentence, all
                        notices to us of a legal nature shall be in writing and shall be sent by certified first-class
                        U.S. mail, return receipt requested, to:
                    </p>
                    <p className="mb-4">
                        <strong>Parooz</strong>, PO Box 311, Redwood City, CA 94062 Attention: General Counsel.
                    </p>
                    <p>
                        If you have any questions about these Terms of Service, or if you need to notify us about a
                        non-legal matter, then please contact us at
                        <span className="text-blue-700 hover:underline">
                            <a href="mailto: info@getparooz.com"> info@getparooz.com</a>
                        </span>
                        or at the postal address set forth above.
                    </p>
                    <p className="mt-8 lg:mt-8 text-2xl">Copyright and Other Intellectual Property Infringement</p>
                    <p>
                        As we ask others to respect our intellectual property rights, we respect the intellectual
                        property rights of others, and require our users and customers to do the same. If you are a
                        copyright owner or an agent thereof and believe that any user content infringes your copyrights,
                        you may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by
                        providing our Copyright Agent with the following information in writing pursuant to 17 U.S.C. §
                        512(c)(3):
                    </p>

                    <div className="mx-4 sm:mx-6 md:mx-10  mb-10 lg:mx-20">
                        <li>
                            A physical or electronic signature of a person authorized to act on behalf of the owner of
                            an exclusive right that is allegedly infringed;
                        </li>
                        <li>
                            Identification of the copyrighted work claimed to have been infringed, or, if multiple
                            copyrighted works on the Services or available through our services are covered by a single
                            notification and identification of each such copyrighted work is both impractical and overly
                            burdensome, a representative list of such works at those locations;
                        </li>
                        <li>
                            Identification of the material that is claimed to be infringing or to be the subject of
                            infringing activity and that is to be removed or access to which is to be disabled and
                            information reasonably sufficient to permit us to locate the material;
                        </li>
                        <li>
                            Your contact information, including email address, postal address, and telephone number;
                        </li>
                        <li>
                            A statement that you have a good faith belief that use of the material in the manner
                            complained of is not authorized by the copyright owner, its agent, or the law; and
                        </li>
                        <li>
                            A statement that the information in the notification is accurate, and under penalty of
                            perjury, that you are authorized to act on behalf of the owner of an exclusive right that is
                            allegedly infringed.
                        </li>
                    </div>
                    <p className="mt-6 mb-6">
                        Our designated Copyright Agent to receive notifications of claimed infringement is Legal
                        Department Copyright Agent, PO Box 311, Redwood City, CA - 94061; e-mail: legal@getparooz.com.
                        For clarity, only DMCA notices should go to the Copyright Agent; any other feedback, comments,
                        requests for technical support, and other communications should be directed to our customer
                        service department. You acknowledge that if you fail to comply with all of the requirements of
                        this section (Copyright and Other Intellectual Property Infringement), your DMCA notice may not
                        be valid.
                    </p>
                    <p className="text-2xl mt-8 mb-8">Third Party Terms</p>
                    <p>
                        You agree that, in addition to these Terms of Service, your use of Parooz is subject to the
                        usage rules set forth in any Android terms of service, if you download Parooz from the Google
                        Play Store, or any other third party platform, developer or distributor end-user license
                        agreement and/or terms and conditions by which you agree to be bound when you download Parooz or
                        otherwise access the Services.
                    </p>
                    <p className="text-2xl mt-8 ">How to Contact Us</p>
                    <p className=" mb-12 ">
                        If you have any questions or concerns regarding these Terms of Service, please contact us as at
                        info@getparooz.com
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Term;
