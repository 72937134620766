import React from 'react';
import Home from '@Components/Home';
import Term from '@Components/Term';
import Privacy from '@Components/Privacy';
import GdprUpdate from '@Components/GdprUpdate';
const routes = [
    {
        path: '/',
        exact: true,
        component: Home
    },
    {
        path: '/term',
        exact: true,
        component: Term
    },
    {
        path: '/privacy',
        exact: true,
        component: Privacy
    },
    {
        path: '/gdpr_update',
        exact: true,
        component: GdprUpdate
    }
];

export default routes;
