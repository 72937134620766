import React, { useEffect, useState } from "react";
import Navbar from "@Components/Navbar";
import Footer from "@Components/Footer";
import {
  Typography,
  TextField,
  Button,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { isMobile, isAndroid, mobileModel, osName } from "react-device-detect";
import { useTranslation } from "react-i18next";
import backgroundOne from "@Images/backgroundOne.png";
import dynamicContent from "@Images/Untitled-2.gif";
import backgroundTwo from "@Images/backgroundTwo.png";
import chatGroup2 from "@Images/chatGroup2.png";
import mobilebg from "@Images/mobile-bg.png";
import mobilebg2 from "@Images/mobile-bg2.png";
import earlymember from "@Images/earlymember.png";
import earlyMemberbg from "@Images/earlyMember-bg.png";
import earlymemberRect from "@Images/earlymemberRect.png";
import backgroundColorOne from "@Images/backgroundColorOne.png";
import { emailIdSubmission, emailApiSubmission } from "@Services.App/api";
import dialogService from "@Services.App/dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor: "white",
    "&:hover": {
      background: "#F9F9F9",
    },
    color: "#0072EE",
    disabled: "#0072EE",
  },

  title: {
    flexGrow: 1,
  },
}));

// https://www.npmjs.com/package/react-device-detect
const Home = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [emailID, setEmailID] = useState("");
  const [device, setDevice] = useState(osName);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const submitEmail = (e) => {
    setSuccess("");
    setError("");
    setLoading(true);
    e.preventDefault();
    const data = { email: emailID, device: device };
    emailApiSubmission({ data })
      .then((response) => {
        console.log("Show retrived data:", response);
        // setSuccess(
        //   `Your email ID ${response.email} has been submitted successfully. Thank you.`
        // );
        // dialogService.success(
        //   `Your email ID  has been submitted successfully. Thank you.`
        // );
        dialogService.success(`${response.message}.`);
      })
      .catch((error) => {
        console.error(error.error);
        dialogService.error(`${error.message.toString()}`);
        // setError(error.message.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const renderError = () => {
    return error ? (
      <Typography paragraph className="font-bold text-white">
        {error}
      </Typography>
    ) : null;
  };
  const renderSuccess = () => {
    return success ? (
      <Typography paragraph className="font-bold text-white">
        {success}
      </Typography>
    ) : null;
  };
  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);
  return (
    <>
      <div className="font-body">
        <Navbar />
        {/* Block 1 start  */}

        <div
          className="items-end hidden w-full h-screen bg-top bg-cover lg:flex "
          style={{
            backgroundImage: `url(${backgroundColorOne})`,
            minHeight: "700px",
          }}
        >
          {/* <div className="flex flex-col justify-end w-full mb-8"> */}
          <div class="w-full mb-10 px-4 ml-auto mr-auto text-center">
            <h1 className="text-6xl font-extrabold text-white font-body">
              <b>
                {t("block1.tagline1")}
                <br></br>
                {t("block1.tagline2")}
              </b>
            </h1>
            <p className="mt-4 text-lg text-gray-300">
              <a
                href={
                  isAndroid ? t("links.AndroidAppLink") : t("links.IosAppLink")
                }
                target="_blank"
              >
                <button
                  class=" hover:bg-blue-500 text-white  py-2 px-4 rounded-lg font-body"
                  style={{ backgroundColor: "#0072EE" }}
                >
                  {t("block1.button")}
                </button>
              </a>
            </p>
          </div>
        </div>
        <div
          className="flex items-end w-full h-screen bg-center bg-cover lg:hidden top-12 "
          style={{ backgroundImage: `url(${backgroundOne})` }}
        >
          {/* <div className="flex flex-col justify-end w-full mb-8"> */}
          <div class="w-full mb-10 px-4 ml-auto mr-auto text-center">
            <h1 className="text-5xl font-extrabold text-white font-body">
              <b>
                {t("block1.tagline1")}
                <br></br>
                {t("block1.tagline2")}
              </b>
            </h1>
            <p className="mt-4 text-lg text-gray-300">
              <a
                href={
                  isAndroid ? t("links.AndroidAppLink") : t("links.IosAppLink")
                }
                target="_blank"
              >
                <button
                  class=" hover:bg-blue-500 text-white  py-2 px-4 rounded-lg font-body"
                  style={{ backgroundColor: "#0072EE" }}
                >
                  {t("block1.button")}
                </button>
              </a>
            </p>
          </div>
        </div>

        {/* Block 2 */}
        <div className="hidden w-full max-h-screen bg-center bg-cover sm:block ">
          <img className="m-auto" src={dynamicContent}></img>
        </div>

        {/* Block 3 */}
        <div className="flex flex-col justify-around hidden mb-12 md:flex-row">
          <div>
            <div className="flex flex-col items-center py-10">
              <h1
                className="text-4xl font-extrabold md:text-5xl font-body"
                style={{ color: "#0072EE" }}
              >
                {t("block3.PeopleConnectedCount")}
              </h1>
              <p className="text-2xl font-semibold font-body">
                {t("block3.PeopleConnected")}
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center py-10 font-body">
              <h1
                className="text-4xl font-extrabold md:text-5xl font-body"
                style={{ color: "#0072EE" }}
              >
                {t("block3.NewCouplesCount")}
              </h1>
              <p className="text-2xl font-semibold font-body">
                {t("block3.NewCouples")}
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center py-10">
              <h1
                className="text-4xl font-extrabold md:text-5xl font-body"
                style={{ color: "#0072EE" }}
              >
                {t("block3.MessagesSentCount")}
              </h1>
              <p className="text-2xl font-semibold font-body">
                {" "}
                {t("block3.MessagesSent")}
              </p>
            </div>
          </div>
        </div>

        {/* Block 4 */}
        <div className="mt-0 sm:mt-8">
          <div
            style={{
              backgroundImage: `url(${backgroundTwo})`,
              height: "calc(100vh+1vh)",
            }}
          >
            <div className="flex flex-col lg:grid lg:grid-cols-2 lg:ml-12">
              <div class="w-full px-4 ml-0 lg:ml-4 mr-0 lg:mr-auto mt-10 lg:mt-auto lg:mb-auto ">
                <h1 className="mb-6 text-4xl font-extrabold text-white lg:text-5xl font-body ">
                  <b>{t("block4.title1")}</b>
                </h1>
                <p className="mb-4 text-lg font-medium text-white font-body">
                  {t("block4.title1p1")}
                </p>
                <p className="text-lg font-medium text-white font-body">
                  {t("block4.title1p2")}
                </p>
              </div>
              <div className="ml-auto mr-auto mt-11 lg:mt-0">
                <img
                  src={chatGroup2}
                  style={{ marginTop: "-6vh" }}
                  alt={t("block4.Img1Alt")}
                />
                {/* <img
                                src={chatGroup}
                                className="hidden lg:block"
                                style={{ height: 'calc(100vh+8vh)', marginTop: '-10vh', marginBottom: '-3vh' }}
                            /> */}
              </div>
            </div>
          </div>
        </div>

        {/* Block 5 */}
        <div className="mt-20 lg:grid lg:grid-cols-2 lg:ml-12 lg:mr-12">
          <div className="w-full px-4 mt-10 ml-0 mr-0 lg:ml-4 lg:mr-auto lg:mt-auto lg:mb-auto ">
            <div className="flex flex-col">
              <div>
                <h1
                  className="mb-6 text-5xl font-extrabold font-body"
                  style={{ color: "#0072EE" }}
                >
                  <b> {t("block5.title1")}</b>
                </h1>
              </div>
              <div>
                <p className="text-lg font-normal font-body">
                  {t("block5.title1p1")}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div>
                <h1
                  className="mb-6 text-5xl font-extrabold mt-9 font-body"
                  style={{ color: "#0072EE" }}
                >
                  <b> {t("block5.title2")}</b>
                </h1>
              </div>
              <div>
                <p className="text-lg font-normal font-body">
                  {t("block5.title2p1")}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <img
              src={mobilebg}
              className="h-auto lg:h-full"
              alt={t("block5.Img1Alt")}
            />
          </div>
        </div>

        {/* block 6 */}
        <div className="flex flex-col-reverse mt-0 lg:ml-12 lg:mr-12 lg:grid lg:grid-cols-2">
          <div className="mt-0">
            <img
              src={mobilebg2}
              className="h-auto lg:h-full"
              alt={t("block6.Img1Alt")}
            />
          </div>
          <div className="w-full px-4 mt-10 ml-0 mr-0 lg:ml-4 lg:mr-auto lg:mt-auto lg:mb-auto ">
            <div className="flex flex-col">
              <div>
                <h1
                  className="mb-6 text-5xl font-extrabold font-body"
                  style={{ color: "#0072EE" }}
                >
                  <b>{t("block6.title1")}</b>
                </h1>
              </div>
              <div>
                <p className="text-lg font-normal">{t("block6.title1p1")}</p>
              </div>
            </div>
            <div className="flex flex-col">
              <div>
                <h1
                  className="mb-6 text-5xl font-extrabold mt-9 "
                  style={{ color: "#0072EE" }}
                >
                  <b1>{t("block6.title2")}</b1>
                </h1>
              </div>
              <div>
                <p className="text-lg font-normal font-body">
                  {t("block6.title2p1")}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* block 7 */}
        <div className="flex flex-col mb-0 lg:grid lg:grid-cols-3 lg:ml-12 lg:mr-12 lg:mb-32">
          <div className="items-center object-fill m-auto bg-center sm:h-auto sm:object-contain">
            <img
              src={earlymemberRect}
              className="block lg:hidden"
              style={{ width: "640px" }}
              alt={t("block7.bg_alt")}
            />
            <img
              src={earlymember}
              alt={t("block7.bg_alt")}
              className="hidden lg:block"
            ></img>
          </div>
          <div
            className="col-span-2 lg:rounded-r-3xl"
            style={{ backgroundImage: `url(${earlyMemberbg})` }}
          >
            <div className="flex flex-col h-full ml-2 mr-2 sm:ml-12">
              <div className="mt-12 mb-12 ml-6 mr-6 lg:m-auto ">
                <div>
                  <span
                    class="font-bold inline-block py-2 px-4  rounded-full text-red-500 uppercase last:mr-0 mr-2 mt-2 mb-6"
                    style={{
                      backgroundColor: "rgb(5,45,85)",
                      color: "rgb(24,249,200)",
                    }}
                  >
                    {isAndroid
                      ? t("block7.isAndroidVIPAccess")
                      : t("block7.isIOSVIPAccess")}
                  </span>
                </div>
                <div className="mb-6 text-4xl font-extrabold text-white lg:text-5xl font-body">
                  <h1>
                    {" "}
                    {isAndroid
                      ? t("block7.Androidtitle")
                      : t("block7.IOStitle")}
                  </h1>
                </div>
                <div className="mb-6 text-lg font-medium text-white font-body">
                  <p>
                    {" "}
                    {isAndroid
                      ? t("block7.Androidtitle1p1")
                      : t("block7.IOStitle1p1")}
                  </p>
                </div>
                <form onSubmit={(e) => submitEmail(e)} validate>
                  <div className="flex flex-col lg:grid lg:grid-cols-3 lg:mb-6 lg:mr-12">
                    <div className="block col-span-2 px-4 py-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500">
                      <TextField
                        required
                        fullWidth
                        placeholder={t("block7.emailPlaceholder")}
                        type="email"
                        name="title"
                        value={emailID}
                        disabled={loading}
                        onChange={(e) => {
                          setEmailID(e.target.value);
                        }}
                      />
                      {/* <input type="email" placeholder={t('block7.emailPlaceholder')}></input> */}
                    </div>
                    <div className="px-4 py-2 mt-8 ml-auto mr-auto font-semibold text-blue-700 border-gray-400 rounded-lg hover:text-blue-900 lg:mt-0 w-60">
                      {/* <Button>{t('block7.emailButton')}</Button> */}
                      <Button
                        type="submit"
                        variant="outlined"
                        size="large"
                        disabled={loading}
                        className={classes.menuButton}
                      >
                        {t("block7.emailButton")}
                      </Button>

                      {/* <button class=""></button> */}
                    </div>
                  </div>
                  {/* {renderSuccess()}

                                    {renderError()} */}
                  <div className="mt-3 h-7 lg:mt-0">
                    <Typography paragraph className="font-bold text-white ">
                      <div className="text-blue-600">.</div> {error || success}
                    </Typography>
                  </div>
                </form>

                {/* <div className="flex flex-col lg:grid lg:grid-cols-3 lg:mb-6 lg:mr-12">
                                    <div className="block col-span-2 px-4 py-3 leading-tight appearance-none focus:outline-none focus:bg-white ">
                                        <TextField
                                            fullWidth
                                            // label="Title"
                                            placeholder={t('block7.emailPlaceholder')}
                                            type="email"
                                            name="title"
                                            style={{ backgroundColor: 'white' }}
                                            variant="outlined"
                                            value={emailID}
                                            disabled={loading}
                                            onChange={(e) => {
                                                setEmailID(e.target.value);
                                            }}></TextField>
                                    </div>

                                    <div className="w-40 ml-auto mr-auto font-semibold text-blue-700 rounded-lg hover:text-blue-900">
                                        {loading ? (
                                            <Button onClick={submitEmail} disabled={true} variant="outlined">
                                                {t('block7.emailButton')}
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={submitEmail}
                                                disabled={false}
                                                variant="outlined"
                                                style={{ backgroundColor: 'white', color: '#0072EE' }}>
                                                {t('block7.emailButton')}
                                            </Button>
                                        )}
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Home;
