import React from 'react';
import Navbar from '@Components/Navbar';
import Footer from '@Components/Footer';
import { Link } from 'react-router-dom';

const Privacy = () => {
    return (
        <>
            <Navbar />
            <div className="mx-4 sm:mx-6 md:mx-10 lg:mx-32">
                <div className="text-center text-lg mt-20 lg:mt-4">
                    <h2 className="font-bold ">Parooz Privacy Policy</h2>
                    <h1 className="mt-2">Last Updated October 15, 2020</h1>
                </div>
                <div className="leading-relaxed">
                    <p className="mt-8">
                        This Privacy Policy explains how Parooz, operated by Tevis, LLC. (together, “Parooz,” “us,”
                        “our,” and “we”) may collect, use, and share information relating to you, and applies to all who
                        use our mobile application as well as our Website (http://www.getparooz.com) (collectively, the
                        “Service”). We may modify this Privacy Policy at any time. Please make sure to visit this page
                        regularly for changes to our privacy practices.
                    </p>
                    <p className="mt-4 underline text-2xl">Information We Collect</p>
                    <p className="mt-4 ">
                        We will collect information about you (“User Information”) when you interact with Parooz and the
                        Service, for example, when you:
                    </p>

                    <div className="mt-5">
                        <li>access or use the Service;</li>
                        <li>
                            register, log into, or create a Parooz account by connecting or linking to your Facebook
                            account;
                        </li>
                        <li>
                            open or respond to our emails or Facebook messages; <br></br>User Information may include
                            the following types of information:
                        </li>
                        <li>
                            Social Networking Information: information that you provide to social networking sites such
                            as Facebook, which may include information about your interests, and may also include
                            information that identifies you specifically, such as your name, your email address, your
                            Facebook ID, and your profile picture. Please note that we only collect Social Networking
                            Information from social networking accounts that you use to register, log into, or create
                            your Parooz account
                        </li>
                        <li>
                            Device Information: information about a device that you use to access, use, or interact with
                            the Service. Such information may include your IP address, date/time of access, Internet
                            service provider, geolocation (including precise location data collected from your mobile
                            device), click data, device make, device model, device operating system, device operating
                            system version, and data connection type.
                        </li>
                        <p className="mt-6 mb-6">
                            We collect User Information using the following standard web technologies :
                        </p>
                        <li>
                            “Cookies” are data files that often include an anonymous unique identifier, and are placed
                            on a visitor’s device or computer. We use cookies, including analytics cookies provided by
                            Facebook, discussed below, in order to collect User Information. For more information about
                            cookies, and how to disable cookies, visit &nbsp;
                            <span className="text-blue-700 hover:underline">
                                <a href="https://www.allaboutcookies.org/" target="_blank">
                                    https://www.allaboutcookies.org
                                </a>
                            </span>
                        </li>
                        <li>
                            “SDKs” or “Software Development Kits” are blocks of code similar to tags and pixels that are
                            embedded into an app that allow us to track certain information relating to your use of apps
                            using our technology.
                        </li>
                        <li>We also use unique device identifiers to track your use of mobile apps.</li>
                        <li>
                            If you use Facebook to access the Service, we may collect User Information from Facebook. To
                            learn more about Facebook’s privacy practices, please visit its Privacy Policy at &nbsp;
                            <span className="text-blue-700 hover:underline">
                                <a href="https://www.facebook.com/about/privacy/" target="_blank">
                                    https://www.facebook.com/about/privacy
                                </a>
                            </span>
                            .
                        </li>
                        <p className="mt-6 text-2xl underline">How We Use Information</p>
                        <p className="mt-6 mb-6">
                            We use User Information to provide the Service, and to enable you to find the true love,
                            casual date, or anything in between. For example, we might use this information to
                            pre-populate a user profile for you within the Service, so that other users can search for,
                            browse, or communicate with you through the Service. Further, we may use this information to
                            contact you, either by email or through another means such as through Facebook, regarding
                            the Service. Additionally, we may use User Information to:
                        </p>
                        <li>Operate, improve, and address issues with our technology and the Service;</li>
                        <li>
                            Conduct research and development, for example analyzing patterns with respect to user
                            interests, or in particular regions;
                        </li>
                        <li>Prepare reports that summarize visitor activity;</li>
                        <li>Evaluate business development opportunities;</li>
                        <li>Determine user response to products and promotions; and</li>
                        <li>Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.</li>
                        <p className="mt-6 text-2xl mb-6 underline">Sharing of Information</p>
                        <p>We may share User Information with others for a variety of reasons. For example:</p>
                        <li>
                            We may share User Information with business partners or potential business partners, for the
                            purpose of expanding, modifying, or developing new offerings through the Service;
                        </li>
                        <li>
                            We may share User Information for the purposes of a business deal (or negotiation of a
                            business deal) involving sale or transfer of all or a part of our business or assets.
                            Business deals may include any merger, financing, acquisition, or bankruptcy transaction or
                            proceeding;
                        </li>
                        <li>
                            We may share User Information for legal, protection, security, and safety purposes,
                            including:
                            <div className="mx-4 sm:mx-6 md:mx-10 lg:mx-32">
                                <li>
                                    To comply with laws or regulatory requirements and to respond to lawful requests and
                                    legal process;
                                </li>
                                <li>
                                    To protect the rights and property of Parooz, our agents, customers, and others,
                                    including enforcing our agreements, policies, and terms of use and protecting our
                                    network and physical assets; and/or
                                </li>
                                <li>
                                    In an emergency, including protecting the safety of our employees and agents, our
                                    customers, or any person.
                                </li>
                            </div>
                        </li>
                        <p className="mt-6 mb-6 text-2xl underline">Your Choices </p>
                        <p>
                            Because the effect of “Do Not Track” signals remains unclear, and there continues to be no
                            consistent industry understanding of how to respond to such a signal, we do not alter our
                            privacy practices when we detect a “Do Not Track” signal from your Internet browser
                        </p>
                        <p className="mt-6 mb-6 text-2xl  underline">Security</p>
                        <p>
                            We use many different measures to help protect information transmitted over or stored on our
                            systems. No transmission of information, however, is guaranteed to be completely secure. In
                            particular, we cannot guarantee that your data will not be disclosed, altered, or accessed
                            accidentally, or through unlawful or unauthorized acts of others.
                        </p>
                        <p className="mt-6 mb-6 text-2xl underline">Changes</p>
                        <p>
                            We reserve the right to modify this privacy policy at any time. If we make material changes
                            to this policy, we will notify you here.
                        </p>
			 <p className="mt-6 mb-6 text-2xl underline">Delete Data or Request More Information</p>
                        <p>
                            You can request deletion of data by emailing us at privacy@getparooz.com, or by visiting the link <a href="https://getparooz.com/gdpr_update">Delete Account</a>.
                        </p>

                        <p className="mt-6 mb-6 text-2xl underline">Contact Us</p>

                        <p>
                            For more information about our privacy practices, if you have questions, or if you would
                            like to make a complaint, please contact us at:
                        </p>
                        <p className="mt-6 mb-6">
                            Parooz <br></br>c/o Tevis, LLC. <br></br> Attn: Privacy Officer, Legal Department<br></br>
                            PO Box 311<br></br> Redwood City, CA 94062 <br></br>USA <br></br>Contact us by e-mail at
                            privacy@getparooz.com
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Privacy;
