import http from '@Services.Core/http';
import env from '@Services.Core/env';

const makeApiUrl = (url) => `${env.getApiBaseUrl()}${url}`;

const makeEmailApiUrl = (url) => `${env.getEmailApiBaseUrl()}${url}`;

// submit email Id
export const emailIdSubmission = ({ data }) => {
    return http.post({ url: makeApiUrl('register'), data });
};

// submit email Id
export const emailApiSubmission = ({ data }) => {
    return http.post({ url: makeEmailApiUrl('subscribe/NmFjODA3MmUtN2NjYS00NDE3LThmZjQtNGE2YWE4NzE2MTkx'), data });
};
