import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import routes from '@Components/Main/routes';
import { AuthProvider } from '@Components/Auth/AuthContext';
import RouteWithSubRoutes from '@Components/Main/RouteWithSubRoutes';
import dialogService from '@Services.App/dialog';
import DialogManager from '@Components/UI/DialogManager';
import ScrollToTop from '@Components/UI/ScrollToTop';
const Main = () => {
    return (
        <AuthProvider>
            <Router>
                <ScrollToTop>
                    <Switch>
                        {routes.map((route, i) => (
                            <RouteWithSubRoutes key={i} {...route} />
                        ))}
                    </Switch>
                </ScrollToTop>
            </Router>
            <DialogManager ref={(mgr) => dialogService.setManager(mgr)} />
        </AuthProvider>
    );
};

export default Main;
