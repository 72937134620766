import React, { createContext, useContext, useState } from 'react';

// Ref: https://reactrouter.com/web/example/auth-workflow

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    return <AuthContext.Provider>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
